import React from "react";
import styled from "styled-components";
import koreanTags from "../data/koreanTags.json";
import Tag from "./Tag";

const koreanRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;

export default function Tags({ tags }) {
  const expandedTags = new Set(
    tags
      .filter((tag) => !koreanRegex.test(tag))
      .reduce((acc, cur) => {
        const tagObjects = [{ name: cur, to: cur }];
        if (Object.hasOwn(koreanTags, cur)) {
          koreanTags[cur].forEach((koreanTag) =>
            tagObjects.push({ name: koreanTag, to: cur })
          );
        }
        return [...acc, ...tagObjects];
      }, [])
  );

  return (
    <Wrapper>
      {[...expandedTags].map((tag) => (
        <Tag key={tag.name} tag={tag} />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: var(--spacing-1);
  flex-wrap: wrap;
`;
