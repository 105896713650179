import React from "react";
import styled from "styled-components";
// import Sidebar from "../Sidebar";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";

function PostDetail({ node }) {
  const { html, timeToRead, frontmatter, fields } = node;
  const { title, date } = frontmatter;
  const { slug, tags } = fields;
  return (
    <Wrapper>
      <Article>
        <Header title={title} date={date} timeToRead={timeToRead} />
        <Content html={html} />
        <Footer date={date} tags={tags} />
      </Article>
      {/* <StickySidebar /> */}
    </Wrapper>
  );
}

const Wrapper = styled.main`
  max-width: 1100px;
  margin: var(--spacing-8) auto;
  display: flex;
  gap: var(--spacing-8);
  justify-content: space-evenly;
`;

const Article = styled.article`
  min-width: 300px;
  max-width: 700px;
`;

// const StickySidebar = styled(Sidebar)`
//   display: none;
//   @media (min-width: 68.75rem) {
//     display: revert;
//     align-self: flex-start;
//     position: sticky;
//     top: 156px;
//   }
// `;

export default PostDetail;
