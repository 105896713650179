import React from "react";

import Layout from "../components/Layout";
import PostDetail from "../components/PostDetail";

export default function ({ pageContext: { node } }) {
  const {
    frontmatter: { title },
  } = node;
  return (
    <Layout title={title} isDetail>
      <PostDetail node={node} />
    </Layout>
  );
}
