import React from "react";
import styled from "styled-components";

export default ({ title, date, timeToRead }) => (
  <Wrapper>
    <Title>
      {title}
      {/* <Header.Subheader>
        {date} · {timeToRead} min read
      </Header.Subheader> */}
    </Title>
    {/* <Tags tags={tags} /> */}
  </Wrapper>
);

const Wrapper = styled.header`
  padding: 64px 32px;
  text-align: center;
`;

const Title = styled.h1`
  overflow-wrap: break-word;
`;
