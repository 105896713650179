import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

export default function Tag({ tag }) {
  return <Wrapper to={`/?tag=${tag.to}`}>{tag.name}</Wrapper>;
}

const Wrapper = styled(Link)`
  padding: var(--spacing-1) var(--spacing-2);
  border-radius: var(--spacing-1);
  background: var(--color-background);
  color: var(--color-text);
  opacity: 0.7;
  font-size: var(--font-size-3);
  transition: opacity 500ms ease 0s;
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 1;
    transition: opacity 0ms ease 0s;
  }
`;
