import React from "react";
import styled from "styled-components";
import Icon from "../Icon";
import Tags from "../Tags";

export default function Footer({ date, tags }) {
  return (
    <>
      <Wrapper>
        <Meta>
          <Icon name="clock" />
          <Date>{date}</Date>
        </Meta>
        <section>
          <Tags tags={tags} />
        </section>
      </Wrapper>
      <License>
        This work is licensed under{" "}
        <a
          href="http://creativecommons.org/licenses/by/4.0/?ref=chooser-v1"
          target="_blank"
          rel="license noopener noreferrer"
          style={{ display: "inline-block" }}
        >
          CC BY 4.0
          <img
            style={{
              height: "22px",
              marginLeft: "13px",
              verticalAlign: "text-bottom",
            }}
            src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"
          />
          <img
            style={{
              height: "22px",
              marginLeft: "3px",
              verticalAlign: "text-bottom",
            }}
            src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1"
          />
        </a>
      </License>
    </>
  );
}

const Wrapper = styled.footer`
  padding: var(--spacing-8) 0;
  display: flex;
  gap: var(--spacing-4);
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Meta = styled.section`
  color: var(--color-text);
  opacity: 0.7;
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
  line-height: 0;
`;

const Date = styled.span`
  font-size: var(--font-size-4);
`;

const License = styled.p`
  margin-top: var(--spacing-4);
  text-align: center;
  font-size: var(--font-size-4);
  font-weight: var(--font-weight-regular);
  color: var(--color-gray);
  font-style: italic;
`;
