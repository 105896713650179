import React from "react";
import styled from "styled-components";

export default function Content({ html }) {
  return <Wrapper dangerouslySetInnerHTML={{ __html: html }} />;
}

const Wrapper = styled.div`
  h2 {
    color: var(--color-secondary);
    margin-top: var(--spacing-12);
    margin-bottom: var(--spacing-4);
  }

  p {
    font-size: var(--font-size-p);
    margin-top: var(--spacing-4);
    margin-bottom: var(--spacing-4);
  }

  a {
    text-decoration: none;
    transition: box-shadow 400ms ease 0s;
    box-shadow: 0px 0px 0px var(--color-primary);
    color: var(--color-primary);
    font-weight: var(--font-weight-regular);

    &:hover {
      transition: box-shadow 100ms ease 0s;
      box-shadow: 0px 2px 0px var(--color-primary);
    }
  }

  img {
    max-width: 100%;
  }

  ul,
  ol {
    margin-block: 1.25em;
    padding-left: 1.625em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-block: 0.5em;
  }

  li::marker {
    color: var(--color-gray);
  }

  blockquote {
    background-color: var(--color-background);
    border-left: 5px solid var(--color-primary);
    border-radius: 0 var(--spacing-2) var(--spacing-2) 0;
    margin: var(--spacing-8) 0px;
    padding: var(--spacing-6) var(--spacing-4);
    color: var(--color-headline);
    font-style: italic;
  }

  blockquote p {
    margin: 0;
  }

  code:not([class*="language-"]) {
    font-family: var(--font-family-mono);
    font-size: 0.9em;
    letter-spacing: -0.5px;
    padding: 4.5px 6px;
    margin: 1px 0px;
    background: var(--color-background);
    color: var(--color-text);
    border-radius: 3px;
  }

  .gatsby-code-title {
    margin-bottom: -0.5rem;
    padding: 0.5em 1em;
    font-family: var(--font-family-mono);
    background-color: #2d2d2d;
    color: white;
    border-radius: var(--spacing-2) var(--spacing-2) 0 0;
    display: inline-block;
  }

  .gatsby-highlight {
    font-size: var(--font-size-3-5);
    border-radius: var(--spacing-2);
    overflow: auto;
    margin-bottom: var(--spacing-8);
  }

  .gatsby-highlight > pre {
    margin: 0;
  }

  .gatsby-highlight-code-line {
    background-color: #4d4d4d;
    display: block;
    margin-right: -1em;
    margin-left: -1em;
    padding-right: 1em;
    padding-left: 0.75em;
    border-left: 0.25em solid var(--color-primary);
  }
`;
